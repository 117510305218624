<template>
  <div class="message">
      <div style="margin-left: 36%;">
        <div class="fz40  text-black">ROCs公会</div>
      </div>
    <div style="background-color: #000;height: 200px;padding: 2%;border-radius: 10px;margin-top: 5%;">
    <div class="df aic" style="margin-top: 20px;">
      <div class="text_box" style="margin-left: 21%;">
        <div class="fz40  text-black">共創</div>
      </div>
      <div class="text_box" @click="showToast">
        <div class="fz40  text-black">共治</div>
      </div>
      <div class="text_box" @click="showToast">
        <div class="fz40  text-black">共享</div>
      </div>
      <div class="text_box" @click="showToast">
        <div class="fz40  text-black">共贏</div>
      </div>
    </div>
    <div class="df  aic" style="margin-top: 20px;">
      <div class="icon_box" @click="showToast">
        <img src="../../assets/images/xg/vip.png" style="width: 30px;height: 30px;"/>
        <div class="fz28  text-black">私域</div>
      </div>
      <div class="icon_box" @click="showToast">
        <img src="../../assets/images/xg/mw.png" style="width: 30px;height: 30px;"/>
        <div class="fz28  text-black">銘文</div>
      </div>
      <div class="icon_box" @click="showToast">
        <img src="../../assets/images/xg/yz.png" style="width: 30px;height: 30px;"/>
        <div class="fz28  text-black">元宇宙</div>
      </div>
      <div class="icon_box" @click="showToast">
        <img src="../../assets/images/xg/sys.png" style="width: 30px;height: 30px;"/>
        <div class="fz28  text-black">實驗室</div>
      </div>
    </div>
    <div class="df  aic" style="margin-top: 20px;">
      <div class="icon_box" @click="goTo('https://t.me/rocfficial')">
        <img src="../../assets/images/xg/tg.png" style="width: 30px;height: 30px;"/>
        <div class="fz28  text-black">TG社群</div>
      </div>
      <div class="icon_box" @click="showToast">
        <img src="../../assets/images/xg/tw.png" style="width: 30px;height: 30px;"/>
        <div class="fz28  text-black">推特</div>
      </div>
      <div class="icon_box" @click="showToast">
        <img src="../../assets/images/xg/youtube.png" style="width: 30px;height: 30px;"/>
        <div class="fz28  text-black">YouTube</div>
      </div>
      <div class="icon_box" @click="showToast">
        <img src="../../assets/images/xg/vk.png" style="width: 30px;height: 30px;"/>
        <div class="fz28  text-black">VK</div>
      </div>
    </div>
    </div>
   <div style="margin-left: 36%;margin-top: 20px;">
     <div class="fz40  text-black">全網公投</div>
   </div>

    <van-cell v-for="item in list" :key="item.id" @click="messageDetails(item)" class="mb40 mt10"
      style="padding-bottom:20px;background-color: #000;height: 50%;border-radius: 10px;">
      <div style="font-weight: bold;font-size: 16px;margin-top: 5px;color: #848E9C;">{{ item.title }}</div>
      <div style="margin-top: 10px;color: #848E9C;font-size: 0.3rem;">时间：{{ item.createTime }}</div>
      <div class="df" style="height: 50px;" >
      <!--  <img :src="item.main_image1"   style="height: 100px;width: 100px;" /> -->
        <div style="font-size: 0.5rem;color: #848E9C;margin-top: 2%;" >&nbsp;&nbsp;&nbsp; {{ item.text_title }}</div>
      </div>
    <!--  <div v-html="change(item.content)" class="pstysd fz16 van-multi-ellipsis--l3 mt10"></div> -->
    </van-cell>

  </div>
</template>
<script>
import { getMessageList } from '@/utils/api.js';
import { Toast } from "vant";
export default {
  name: 'message',
  data() {
    return {
      list: []
    }
  },
  created() {
    this.initData();
  },
  methods: {
   initData() {
     let params = {
       type : 3
     }
     getMessageList(params).then((res) => {
       // console.log(res, '111');
       this.list = res.data
     })
   },
   goTo(to) {
     window.open(to);
   },
   showToast() {
     Toast({
       message: '敬請期待',
       icon: 'success',
       className: 'noticeWidth'
     });
   },
    onClickLeft() {
      this.$router.go(-1)
    },
    change(strValue) {
      // console.log(strValue,'1');
      strValue = strValue.replace(/&amp;/g, "&");
      strValue = strValue.replace(/&lt;/g, "<");
      strValue = strValue.replace(/&gt;/g, ">");
      strValue = strValue.replace(/&quot;/g, "\"");
      strValue = strValue.replace(/&#39;/g, "'");
      // console.log(strValue,'2');
      return strValue;
    },
    messageDetails(item) {
      this.$router.push({
        name: 'messageDetails',
        query: {
          items: JSON.stringify(item)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__title {
  padding-top: 10px !important;
  font-size: 20px;
}
::v-deep(.van-nav-bar__left) {
  font-size: 32px !important;
  font-weight: bold;
}

.message {
  background: #1e2329;
  font-size: 14px;
  min-height: 100%;
  padding: 40px 30px;
  font-family: "pingfang" !important;


  ::v-deep(.van-nav-bar__title) {
    font-weight: bold !important;
    font-size: 30px !important;
    height: 60px;
  }

  ::v-deep(.van-nav-bar__content) {
    height: 60px;
    margin: auto;
  }

  .pstysd {

    // overflow: hidden;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 3;
    // height: 200px;
    img {
      width: 100%;
    }
  }
}
.text_box{
  background: #000;
  border-radius: 10px;
  width: 15%;
  text-align: center;
}
.icon_box{
  background: #000;
  border-radius: 10px;
  margin: auto;
  width: 20%;
  text-align: center;
}
</style>
